import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PostCard from "components/PostCard/PostCard"
import Pagination from "components/Pagination/Pagination"
import BlogPostsWrapper from "./style"

type PostsProps = {}

const Posts: React.FunctionComponent<PostsProps> = props => {
  const Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulPost(sort: { fields: [updatedAt], order: DESC }) {
        group(field: id) {
          pageInfo {
            pageCount
            currentPage
          }
          totalCount
        }
        edges {
          node {
            title
            slug
            updatedAt(formatString: "DD [<span>] MMM [</span>]")
            tags
            cover {
              fluid(maxWidth: 1170, maxHeight: 690, resizingBehavior: SCALE) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            summary {
              summary
            }
          }
        }
      }
    }
  `)

  const Posts = Data.allContentfulPost.edges
  const TotalPage = Data.allContentfulPost.group[0].pageInfo.pageCount
  const CurrentPage = Data.allContentfulPost.group[0].pageInfo.currentPage

  return (
    <BlogPostsWrapper>
      {Posts.map(({ node }: any) => {
        const title = node.title || node.slug
        return (
          <PostCard
            key={node.slug}
            title={title}
            image={
              node.cover == null
                ? null
                : node.cover.fluid
            }
            url={node.slug}
            description={node.summary.summary}
            date={node.updatedAt}
            tags={node.tags}
          />
        )
      })}

      {TotalPage >> 1 ? (
        <Pagination
          nextLink="/page/2"
          currentPage={CurrentPage}
          totalPage={TotalPage}
        />
      ) : (
          ""
        )}
    </BlogPostsWrapper>
  )
}

export default Posts
